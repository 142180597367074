import { useContext } from 'react';
import UtilsContext from '../context/utils/UtilsContext';

const Home = () => {
  const { lightTheme, theme } = useContext(UtilsContext);
  const darkStyle = {
    boxShadow: theme.dark.selfintro.boxShadow,
  };
  const lightStyle = {
    boxShadow: theme.light.selfintro.boxShadow,
  };

  return (
    <div id="home">
      <div className="relative">
        <img
          id="profile-img"
          src="https://zkzhang-projects-media.s3.us-west-2.amazonaws.com/profile_img.jpg"
          alt="Profile avatar"
          height="144px"
          width="144px"
        />
        <p id="self-intro" style={lightTheme ? lightStyle : darkStyle}>
          Hi, I'm Kevin Zhang
        </p>
      </div>
      <div className="text-center text-lg mt-6">
        <p>Software Engineer</p>
        <p>@ Seattle, WA</p>
      </div>
    </div>
  );
};

export default Home;
