import { useContext } from 'react';
import GaIcon from '../icon/GaIcon';
import { BsArrowClockwise } from 'react-icons/bs';
import UtilsContext from '../../context/utils/UtilsContext';

const GaReport = () => {
  const { pageView } = useContext(UtilsContext);
  const { pageviewTotal, pageview7Days } = pageView;

  return (
    <div className="flex flex-col justify-center items-center text-sm cursor-default">
      <p className="mb-2">Page View / Visits</p>
      <div className="flex flex-col justify-center items-start">
        <p className="flex">
          <span className="mr-2">Total:</span>
          <span className="mr-2 italic font-bold">
            {pageviewTotal ? (
              new Intl.NumberFormat().format(Number(pageviewTotal))
            ) : (
              <BsArrowClockwise className="animate-spin h-4 w-4" />
            )}
          </span>
        </p>
        <p className="flex">
          <span className="mr-2">Last 7 days:</span>
          <span className="mr-2 italic font-bold">
            {pageview7Days ? (
              new Intl.NumberFormat().format(Number(pageview7Days))
            ) : (
              <BsArrowClockwise className="animate-spin h-4 w-4" />
            )}
          </span>
        </p>
      </div>
      <div className="flex justify-center items-center mt-2">
        <span className="mr-1">Powered By</span>
        <a
          href="https://developers.google.com/analytics"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GaIcon />
        </a>
      </div>
    </div>
  );
};

export default GaReport;
