import { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthContext from '../../context/auth/AuthContext';
import UtilsContext from '../../context/utils/UtilsContext';
import { logout } from '../../context/auth/AuthActions';

const DashControl = () => {
  const { path } = useContext(UtilsContext);
  const { dispatch } = useContext(AuthContext);
  const currentPath = window.location.pathname;
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    dispatch({
      type: 'SET_AUTH',
      payload: { auth: undefined, user: undefined },
    });
    navigate(path.admin);
  };

  return (
    <div id="dash-control" className="tabs ml-auto font-semibold flex-none">
      <Link
        to={path.dashboard}
        className={`tab tab-bordered bg-transparent text-inherit ${
          currentPath === path.dashboard ? 'tab-active' : 'opacity-40'
        }`}
      >
        Dashboard
      </Link>
      <Link
        to={path.message}
        className={`tab tab-bordered bg-transparent text-inherit ${
          currentPath === path.dashMessage ? 'tab-active' : 'opacity-40'
        }`}
      >
        Messages
      </Link>
      <Link
        to={path.profile}
        className={`tab tab-bordered bg-transparent text-inherit ${
          currentPath === path.dashProfile ? 'tab-active' : 'opacity-40'
        }`}
      >
        Profile
      </Link>
      <div className="tab text-inherit italic hover:underline" onClick={handleLogout}>
        Log out
      </div>
    </div>
  );
};

export default DashControl;
