const GaIcon = () => {
  return (
    // Credit: https://www.vectorlogo.zone/logos/google_analytics/index.html
    <svg
      height="18"
      viewBox="26 -29 310 60"
      width="93"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#757575">
        <path d="m211.1-6.54 4.116 10.806h-7.976zm-1.544-3.602-9.005 23.67h3.345l2.316-6.432h10.034l2.316 6.432h3.345l-9.005-23.67h-3.345zm48.624 23.67h3.087v-23.67h-3.087zm-31.655-13.894c1.03-1.544 3.087-2.83 5.146-2.83 4.116 0 6.175 2.83 6.175 6.947v10.034h-3.087v-9.52c0-3.345-1.8-4.63-4.116-4.63-2.573 0-4.374 2.573-4.374 4.888v9.005h-3.087v-16.466h3.087l.257 2.573zm13.895 9.006c0-3.602 3.345-5.66 7.204-5.66 2.316 0 3.86.515 4.374 1.03v-.515c0-2.573-2.058-3.86-4.116-3.86-1.8 0-3.345.772-3.86 2.316l-2.822-1.288c.515-1.544 2.573-3.86 6.7-3.86 3.86 0 7.204 2.316 7.204 6.947v9.777h-2.83v-2.327h-.257c-.772 1.286-2.573 2.83-5.403 2.83-3.345 0-6.175-2.058-6.175-5.403m11.578-2.058s-1.286-1.03-3.86-1.03c-3.087 0-4.374 1.8-4.374 3.087 0 1.8 1.8 2.573 3.345 2.573 2.316 0 4.888-2.058 4.888-4.63" />
        <path
          d="m265.632 20.73 3.602-8.5-6.432-14.922h3.087l4.888 11.32 4.888-11.32h3.087l-10.032 23.422h-3.087zm45.28-18.008c-.773-2.06-2.573-3.345-4.373-3.345-2.573 0-4.888 2.316-4.888 5.66s2.316 5.66 4.888 5.66c1.8 0 3.602-1.286 4.374-3.087l2.573 1.544c-1.286 2.83-3.86 4.63-6.947 4.63-4.374 0-7.976-3.86-7.976-8.747 0-5.146 3.602-8.748 7.976-8.748 3.087 0 5.66 1.8 6.947 4.63l-2.573 1.8z"
          fillRule="evenodd"
        />
        <path d="m321.976 14.042c4.116 0 6.175-2.316 6.175-5.146 0-6.175-9.005-3.86-9.005-7.46 0-1.286 1.03-2.058 2.83-2.058s3.602.772 4.374 2.058l1.8-1.8c-1.03-1.286-3.86-3.087-6.432-3.087-3.86 0-5.917 2.316-5.917 5.146 0 5.917 9.262 3.86 9.262 6.947 0 1.544-1.03 2.573-3.087 2.573s-3.087-1.286-4.116-2.573l-2.316 1.8c1.544 1.544 3.86 3.602 6.432 3.602zm-29.33-.515h3.087v-16.465h-3.087v16.466z" />
        <path
          d="m293.933-10.914c1.286 0 2.058 1.03 2.058 2.058 0 1.286-1.03 2.058-2.058 2.058s-2.058-1.03-2.058-2.058 1.03-2.058 2.058-2.058zm-4.373 21.87.515 2.316h-3.087c-2.83 0-4.116-2.058-4.116-5.146v-7.976h-3.087v-3.087h3.087v-4.888h3.087v4.888h3.602v3.087h-3.602v8.5c0 2.316 1.8 2.316 3.602 2.316z"
          fillRule="evenodd"
        />
        <path d="m99.944-.623v3.86h9.005c-.257 2.058-1.03 3.602-2.058 4.63-1.286 1.286-3.345 2.83-6.947 2.83-5.403 0-9.777-4.374-9.777-10.034 0-5.403 4.374-10.034 9.777-10.034 3.087 0 5.146 1.286 6.7 2.573l2.573-2.573c-2.316-2.058-5.146-3.86-9.262-3.86-7.46 0-13.893 6.175-13.893 13.636s6.422 13.635 13.883 13.635c4.116 0 7.204-1.286 9.52-3.86 2.573-2.573 3.345-5.917 3.345-8.748 0-.772 0-1.8-.257-2.316h-12.6zm22.898-3.077c-4.888 0-8.748 3.602-8.748 8.748s3.86 8.747 8.748 8.747 8.748-3.86 8.748-8.747c0-5.146-3.86-8.748-8.748-8.748zm0 14.15c-2.573 0-4.888-2.316-4.888-5.403s2.316-5.403 4.888-5.403 4.888 2.058 4.888 5.403-2.316 5.403-4.888 5.403zm42.194-12.35c-1.03-1.03-2.573-2.058-4.888-2.058-4.374 0-8.233 3.86-8.233 8.748s3.86 8.747 8.233 8.747c2.058 0 3.86-1.03 4.63-2.058h.257v1.286c0 3.345-1.8 5.146-4.63 5.146-2.316 0-3.86-1.8-4.374-3.087l-3.345 1.286c1.03 2.316 3.602 5.146 7.718 5.146 4.63 0 8.5-2.573 8.5-9.262v-15.95h-3.602v2.058zm-4.374 12.35c-2.573 0-4.63-2.316-4.63-5.403s2.058-5.403 4.63-5.403 4.63 2.316 4.63 5.403-2.058 5.403-4.63 5.403zm-18.782-14.15c-4.888 0-8.748 3.602-8.748 8.748s3.86 8.747 8.748 8.747 8.748-3.86 8.748-8.747c.257-5.146-3.86-8.748-8.748-8.748zm0 14.15c-2.573 0-4.888-2.316-4.888-5.403s2.316-5.403 4.888-5.403 4.888 2.058 4.888 5.403-2.316 5.403-4.888 5.403zm29.845-23.422h3.86v26.757h-3.86zm14.408 23.412c-2.058 0-3.345-1.03-4.374-2.573l11.835-4.888-.515-1.03c-.772-2.058-3.087-5.66-7.46-5.66-4.63 0-8.233 3.602-8.233 8.748 0 4.888 3.86 8.747 8.748 8.747 4.116 0 6.432-2.573 7.46-3.86l-3.087-2.058c-1.03 1.544-2.316 2.573-4.374 2.573zm-.257-10.806c1.544 0 2.83.772 3.345 2.058l-7.976 3.345c0-3.86 2.573-5.403 4.63-5.403z" />
      </g>
      <g transform="matrix(.264928 0 0 .264927 28.551061 -25.049684)">
        <path
          d="m130 29v132c0 14.77 10.2 23 21 23 10 0 21-7 21-23v-131c0-13.54-10-22-21-22s-21 9.33-21 21z"
          fill="#f9ab00"
        />
        <g fill="#e37400">
          <path d="m75 96v65c0 14.77 10.2 23 21 23 10 0 21-7 21-23v-64c0-13.54-10-22-21-22s-21 9.33-21 21z" />
          <circle cx="41" cy="163" r="21" />
        </g>
      </g>
    </svg>
  );
};

export default GaIcon;
