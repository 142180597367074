import { Route, Routes, Navigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/AuthContext';
import { getAuth } from '../../context/auth/AuthActions';
import UtilsContext from '../../context/utils/UtilsContext';
import Home from '../../pages/Home';
import Project from '../../pages/Project';
import About from '../../pages/About';
import Contact from '../../pages/Contact';
import Page404 from '../../pages/Page404';
import Page401 from '../../pages/Page401';
import Version from '../../pages/Version';
import Admin from '../../pages/Admin';
import Dashboard from '../../pages/Dashboard';
import DashboardProfile from '../dashboard/DashboardProfile';
import DashboardMessage from '../dashboard/DashboardMessage';
import DashboardHome from '../dashboard/DashboardHome';
import Resume from '../../pages/Resume';
import Loading from '../utils/Loading';

const PageRoutes = () => {
  const { path } = useContext(UtilsContext);
  const { auth, dispatch } = useContext(AuthContext);

  const RequireAuth = ({ children, redirectTo }) => {
    switch (auth) {
      case undefined:
        return <Loading />;
      case true:
        return children;
      case false:
        return <Navigate to={redirectTo} replace={true} />;
      default:
        return null;
    }
  };

  const handleAuthentication = async () => {
    try {
      const res = await getAuth();
      dispatch({
        type: 'SET_AUTH',
        payload: { auth: res.auth, user: res.user },
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path={path.home} element={<Home />} />
      <Route path={path.about} element={<About />} />
      <Route path={path.project} element={<Project />} />
      <Route path={path.contact} element={<Contact />} />
      <Route path={path.version} element={<Version />} />
      <Route path={path.admin} element={<Admin />} />
      <Route path={path.resume} element={<Resume />} />
      <Route
        path={path.dashboard}
        element={
          <RequireAuth redirectTo={path.unauth}>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="" element={<DashboardHome />} />
        <Route path={path.message} element={<DashboardMessage />} />
        <Route path={path.profile} element={<DashboardProfile />} />
      </Route>
      <Route path={path.unauth} element={<Page401 />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default PageRoutes;
