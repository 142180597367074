import { BsBug, BsPinAngle, BsPlusCircle } from 'react-icons/bs';

const LogBlock = ({ content, isCurrent, isLast }) => {
  return (
    <div
      className={`mb-4 mx-8 pb-4 border-dashed border-gray-500 cursor-default ${
        !isLast && 'border-b-2'
      }`}
    >
      <div className="flex justify-start items-center mb-2 cursor-default">
        <span className="mr-2">Version {content.ver}</span>
        {isCurrent && (
          <span className="bg-indigo-500 text-white px-1 text-xs">current</span>
        )}
      </div>
      <div className="">
        <div className="flex justify-start items-center">
          <BsPlusCircle />
          <span className="mx-2 cursor-default">Feature Additions:</span>
        </div>
        <ul className="list-disc ml-6">
          {content.features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        {content.bugs && (
          <>
            <div className="flex justify-start items-center mt-2">
              <BsBug />
              <span className="mx-2 cursor-default">Bug Fixes:</span>
            </div>
            <ul className="list-disc ml-6">
              {content.bugs?.map((bug, index) => (
                <li key={index}>{bug}</li>
              ))}
            </ul>
          </>
        )}
        {content.issues && (
          <>
            <div className="flex justify-start items-center mt-2">
              <BsPinAngle />
              <span className="mx-2 cursor-default">Known Issues:</span>
            </div>
            <ul className="list-disc ml-6">
              {content.issues?.map((issue, index) => (
                <li key={index}>{issue}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default LogBlock;
