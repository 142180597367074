import { useContext } from 'react';
import AlertContext from '../../context/alert/AlertContext';
import { BsCheck2Circle, BsExclamationOctagon } from 'react-icons/bs';

const AlertMessage = () => {
  const { alert } = useContext(AlertContext);

  return (
    <div
      id="alert-message"
      className={`alert shadow-lg ${
        alert?.success ? 'alert-success' : 'alert-error'
      }`}
      style={{ visibility: alert ? 'visible' : 'hidden' }}
    >
      <div className="text-lg mx-auto font-semibold">
        {alert?.success ? <BsCheck2Circle /> : <BsExclamationOctagon />}
        <span>{alert?.content}</span>
      </div>
    </div>
  );
};

export default AlertMessage;
