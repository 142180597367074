import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UtilsContext from '../context/utils/UtilsContext';

const Resume = () => {
  const { path } = useContext(UtilsContext);
  return <Navigate to={path.contact} replace />;
};

export default Resume;
