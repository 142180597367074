import { useState, useContext } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  BsArrowLeftRight,
  BsEye,
  BsEyeSlash,
  BsFillPersonFill,
} from 'react-icons/bs';
import AuthContext from '../context/auth/AuthContext';
import { login, register } from '../context/auth/AuthActions';
import UtilsContext from '../context/utils/UtilsContext';
import AlertContext from '../context/alert/AlertContext';

const Admin = () => {
  const navigate = useNavigate();
  const { path } = useContext(UtilsContext);
  const { auth, dispatch } = useContext(AuthContext);
  const { alert, setAlert } = useContext(AlertContext);
  const [isLogin, setIsLogin] = useState(true);
  const [isPassword1Visible, setIsPassword1Visible] = useState(false);
  const [isPassword2Visible, setIsPassword2Visible] = useState(false);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [code, setCode] = useState('');
  const adminStyle =
    'flex flex-col m-auto w-full py-6 px-10 max-w-xl justify-start items-center border-dashed border-2 border-gray-500';

  if (auth) {
    return <Navigate to={path.dashboard} replace />;
  }

  const handleLogin = async e => {
    setIsBtnDisabled(true);
    e.preventDefault();

    try {
      const res = await login(username.trim(), password);
      setAlert('Successfully logged in', true, 3000);
      document.getElementById('form').reset();
      dispatch({
        type: 'SET_AUTH',
        payload: { auth: res.auth, user: res.user },
      });
    } catch (err) {
      setIsBtnDisabled(false);
      if (err.response) {
        setAlert(
          err.response.status === 401
            ? 'Invalid credentials'
            : 'Failed logging in',
          false,
          4000
        );
      }
    }
  };

  const handleRegister = async e => {
    setIsBtnDisabled(true);
    e.preventDefault();

    if (password !== password2) {
      setAlert('Passwords do not match', false, 3000);
      setIsBtnDisabled(false);
      return;
    }

    try {
      const res = await register(username.trim(), password, code);
      dispatch({
        type: 'SET_AUTH',
        payload: { auth: res.auth, user: res.user },
      });
      document.getElementById('form').reset();
      navigate(path.dashboard);
    } catch (err) {
      setAlert(err.response.data.error, false, 5000);
    }
    setIsBtnDisabled(false);
  };

  const handleSwitch = () => {
    setIsLogin(!isLogin);
    document.getElementById('form').reset();
  };

  return (
    <div id="admin" className="flex">
      <div
        className={alert ? `${adminStyle}` : adminStyle}
      >
        <div className="form-title">
          <BsFillPersonFill />
          <span className="ml-2">Admin {isLogin ? 'Login' : 'Register'}</span>
        </div>
        <div className="flex w-full justify-end items-center text-xs mr-4">
          <div
            className="flex justify-center items-center cursor-pointer mb-2"
            onClick={handleSwitch}
          >
            <BsArrowLeftRight />
            <span className="expand-underline ml-1">
              {isLogin ? 'Register' : 'Login'}
            </span>
          </div>
        </div>
        <form id="form" onSubmit={isLogin ? handleLogin : handleRegister}>
          <div className="form-div">
            <label className="login-label" htmlFor="username">
              Username
            </label>
            <input
              className="login-input"
              type="text"
              name="username"
              id="username"
              autoComplete="off"
              onChange={e => setUsername(e.target.value)}
              required
            />
          </div>

          <div className="form-div">
            <label className="login-label" htmlFor="password">
              Password
            </label>
            <input
              className="login-input"
              type={isPassword1Visible ? 'text' : 'password'}
              name="password"
              id="password"
              autoComplete="off"
              onChange={e => setPassword(e.target.value)}
              required
            />
            <span
              className="mr-2 cursor-pointer"
              onClick={() => setIsPassword1Visible(!isPassword1Visible)}
            >
              {isPassword1Visible ? <BsEye /> : <BsEyeSlash />}
            </span>
          </div>

          {!isLogin && (
            <div className="form-div">
              <label className="login-label" htmlFor="password2">
                Confirm
              </label>
              <input
                className="login-input"
                type={isPassword2Visible ? 'text' : 'password'}
                name="password2"
                id="password2"
                autoComplete="off"
                onChange={e => setPassword2(e.target.value)}
                required
              />
              <span
                className="mr-2 cursor-pointer"
                onClick={() => setIsPassword2Visible(!isPassword2Visible)}
              >
                {isPassword2Visible ? <BsEye /> : <BsEyeSlash />}
              </span>
            </div>
          )}
          {!isLogin && (
            <div className="form-div">
              <label
                className="login-label"
                htmlFor="code"
                style={{ width: '106px' }}
              >
                ID Code
              </label>
              <input
                className="login-input"
                type={isCodeVisible ? 'text' : 'password'}
                name="code"
                id="code"
                autoComplete="off"
                onChange={e => setCode(e.target.value)}
                required
              />
              <span
                className="mr-2 cursor-pointer"
                onClick={() => setIsCodeVisible(!isCodeVisible)}
              >
                {isCodeVisible ? <BsEye /> : <BsEyeSlash />}
              </span>
            </div>
          )}

          <button
            type="submit"
            className="btn-customized"
            disabled={isBtnDisabled}
            style={{ backgroundColor: `${isBtnDisabled ? 'gray' : ''}` }}
          >
            {isBtnDisabled ? 'PROCESSING ...' : isLogin ? 'LOGIN' : 'REGISTER'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Admin;
