import { FaCircleNotch } from 'react-icons/fa';

const Loading = () => {
  return (
    <div className="h-full flex flex-col justify-center items-center">
      <FaCircleNotch className="animate-spin h-10 w-10 text-indigo-500 mb-2" />
      <div className="animate-pulse">Loading</div>
    </div>
  );
};

export default Loading;
