import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineFingerPrint } from 'react-icons/hi';
import AuthContext from '../../context/auth/AuthContext';
import UtilsContext from '../../context/utils/UtilsContext';

const AdminBadge = () => {
  const { auth } = useContext(AuthContext);
  const { path } = useContext(UtilsContext);

  return (
    <Link
      to={path.dashboard}
      id="admin-badge"
      className={`absolute flex justify-center items-center top-4 text-2xl ${
        !auth && 'hidden'
      }`}
    >
      <HiOutlineFingerPrint />
      <span className="text-lg mx-1">Admin</span>
    </Link>
  );
};

export default AdminBadge;
