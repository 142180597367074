import { useState, useContext } from 'react';
import AuthContext from '../../context/auth/AuthContext';
import { changePassword } from '../../context/auth/AuthActions';
import AlertContext from '../../context/alert/AlertContext';
import { BsFillLockFill } from 'react-icons/bs';

const DashboardProfile = () => {
  const { user } = useContext(AuthContext);
  const { setAlert } = useContext(AlertContext);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const contactStyle =
    'flex flex-col mx-auto py-6 px-10 max-w-xl justify-start items-center border-dashed border-2 border-gray-500';

  const handleChangePassword = async e => {
    setIsBtnDisabled(true);
    e.preventDefault();

    if (newPassword !== newPassword2) {
      setAlert('Passwords do not match', false, 5000);
      setIsBtnDisabled(false);
    } else if (newPassword.length < 10) {
      setAlert('Password must be at least 10 characters', false, 5000);
      setIsBtnDisabled(false);
    } else {
      try {
        await changePassword(user, currentPassword, newPassword);
        document.getElementById('admin-password').reset();
        setAlert('Password has been updated', true, 3000);
      } catch (err) {
        setAlert(err.response.data?.error, false, 5000);
      } finally {
        setIsBtnDisabled(false);
      }
    }
  };

  return (
    <div id="profile" className="m-4">
      <div className="flex flex-col justify-center items-center">
        <div
          className={contactStyle}
        >
          <div className="form-title">
            <BsFillLockFill />
            <span className="ml-2 cursor-default">Password Update</span>
          </div>
          <form id="form" onSubmit={handleChangePassword}>
            <div className="form-div">
              <label className="profile-label" htmlFor="username">
                Username
              </label>
              <input
                className="login-input text-inherit"
                type="text"
                name="username"
                id="username"
                autoComplete="off"
                disabled
                value={user ? user : ''}
              />
            </div>

            <div className="form-div">
              <label className="profile-label" htmlFor="currentPassword">
                Current Password
              </label>
              <input
                className="login-input"
                type="password"
                name="currentPassword"
                id="currentPassword"
                autoComplete="off"
                onChange={e => setCurrentPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-div">
              <label className="profile-label" htmlFor="newPassword">
                New Password
              </label>
              <input
                className="login-input"
                type="password"
                name="newPassword"
                id="newPassword"
                autoComplete="off"
                onChange={e => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-div">
              <label className="profile-label" htmlFor="newPassword2">
                Confirm Password
              </label>
              <input
                className="login-input"
                type="password"
                name="newPassword2"
                id="newPassword2"
                autoComplete="off"
                onChange={e => setNewPassword2(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className="btn-customized"
              disabled={isBtnDisabled}
              style={{ backgroundColor: isBtnDisabled && 'gray' }}
            >
              {isBtnDisabled ? 'PROCESSING ...' : 'UPDATE PASSWORD'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DashboardProfile;
