import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import axios from 'axios';
import { BsFillCircleFill } from 'react-icons/bs';
import MessageDetail from './MessageDetail';
import Loading from '../utils/Loading';
import UtilsContext from '../../context/utils/UtilsContext';
import AlertContext from '../../context/alert/AlertContext';

const DashboardMessage = () => {
  const [contactInfo, setContactInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { lightTheme } = useContext(UtilsContext);
  const { setAlert } = useContext(AlertContext);
  const [showDetail, setShowDetail] = useState(false);
  const [detailContact, setDetailContact] = useState({});
  const thStyle = lightTheme ? 'bg-gray-200' : 'bg-indigo-800';

  const getAllContactInfo = async () => {
    const res = await axios({
      method: 'get',
      withCredentials: true,
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/contacts`,
    });
    setContactInfo(res.data.data);
    setIsLoading(false);
  };

  const handleRowClick = async contact => {
    try {
      await axios({
        method: 'put',
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/contacts/${contact._id}/view`,
        data: { isViewed: true },
      });
    } catch (err) {
      setAlert(err.response.data.error, false, 3000);
    }
    setDetailContact(contact);
    setShowDetail(true);
  };

  const handleClose = () => {
    setShowDetail(false);
  };

  useEffect(() => {
    getAllContactInfo();
  }, []);

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center" style={{ height: '70vh' }}>
        <Loading />
      </div>
    );
  }

  return (
    <div id="dashboard-messages" className="pt-2">
      {showDetail && (
        <MessageDetail
          detailContact={detailContact}
          handleClose={handleClose}
          getAllContactInfo={getAllContactInfo}
        />
      )}
      <table
        className="w-full mx-auto"
        style={{ borderSpacing: '0 0', borderCollapse: 'separate' }}
      >
        <thead>
          <tr>
            <th className={`${thStyle}`}></th>
            <th className={`${thStyle}`}>Name</th>
            <th className={`table-col-hide ${thStyle}`}>Email</th>
            <th className={`table-col-hide ${thStyle}`}>Subject</th>
            <th className={`${thStyle}`}>Time Sent</th>
            <th className={`${thStyle}`}></th>
          </tr>
        </thead>
        <tbody>
          {contactInfo
            ?.slice(0)
            .reverse()
            .map((row, index) => (
              <tr
                className={`${
                  lightTheme ? 'hover:bg-gray-200' : 'hover:bg-gray-600'
                } ${row.requiresResume ? 'text-red-500' : ''}`}
                key={index}
              >
                <td className="w-5">
                  <div>
                    {!row.isViewed && (
                      <BsFillCircleFill className="h-1 w-1 mx-auto" />
                    )}
                  </div>
                </td>
                <td
                  className="text-ellipsis whitespace-nowrap overflow-hidden"
                  style={{ maxWidth: '6rem' }}
                >
                  {row.name}
                </td>
                <td
                  className="table-col-hide text-ellipsis whitespace-nowrap overflow-hidden"
                  style={{ maxWidth: '12rem' }}
                >
                  {row.email}
                </td>
                <td
                  className="table-col-hide text-ellipsis whitespace-nowrap overflow-hidden"
                  style={{ maxWidth: '12rem' }}
                >
                  {row.subject}
                </td>
                <td className="text-center">
                  {moment(row.createdAt).format('MM/DD/YY - HH:mm')}
                </td>
                <td>
                  <button
                    className="btn-link text-inherit"
                    onClick={() => handleRowClick(row)}
                  >
                    details
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <th className={thStyle}></th>
            <th className={thStyle}>Name</th>
            <th className={`table-col-hide ${thStyle}`}>Email</th>
            <th className={`table-col-hide ${thStyle}`}>Subject</th>
            <th className={thStyle}>Time Sent</th>
            <th className={thStyle}></th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default DashboardMessage;
