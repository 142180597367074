import { useState } from 'react';
import {
  BsCodeSlash,
  BsLink45Deg,
  BsHandThumbsUp,
  BsHandThumbsUpFill,
  BsHandThumbsDown,
  BsHandThumbsDownFill,
} from 'react-icons/bs';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ImageLoader from '../utils/ImageLoader';

// Idea from Traversy Media: https://www.youtube.com/watch?v=FiGmAI5e91M&t=2028s
const ProjectCard = ({ info, imgProjects }) => {
  const [isLike, setIsLike] = useState(false);
  const [isDislike, setIsDislike] = useState(false);
  const [likes, setLikes] = useState(10);
  const [dislikes, setDislikes] = useState(2);
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleLike = () => {
    setIsLike(!isLike);
    if (isLike) {
      setLikes(likes - 1);
    } else {
      setLikes(likes + 1);
      setIsDislike(false);
    }
  };

  const handleDislike = () => {
    setIsDislike(!isDislike);
    if (isDislike) {
      setDislikes(dislikes - 1);
    } else {
      setDislikes(dislikes + 1);
      setIsLike(false);
    }
  };

  const handleImgClick = id => {
    setIsOpen(true);
    setImgIndex(id - 1);
  };

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={imgProjects[imgIndex].image}
          nextSrc={imgProjects[(imgIndex + 1) % imgProjects.length].image}
          prevSrc={
            imgProjects[
              (imgIndex + imgProjects.length - 1) % imgProjects.length
            ].image
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setImgIndex(
              (imgIndex + imgProjects.length - 1) % imgProjects.length
            )
          }
          onMoveNextRequest={() =>
            setImgIndex((imgIndex + 1) % imgProjects.length)
          }
          imageTitle={imgProjects[imgIndex].name}
          imageCaption={imgProjects[imgIndex].desc}
        />
      )}

      <div
        id="project-card"
        className="grid grid-rows-1 grid-cols-3 mx-auto mb-16 max-w-5xl cursor-default pb-8 border-dashed border-b-2 border-gray-500"
        // style={lightTheme ? lightStyle : darkStyle}
      >
        <div className="media col-span-1 pt-2">
          {info?.isVideo ? (
            <video
              controls
              className="w-full h-auto flex mx-auto"
              style={{ width: '80%' }}
            >
              <source src={info.image} type="video/mp4" />
            </video>
          ) : (
            <div className="tooltip tooltip-bottom" data-tip="Large View">
              <ImageLoader width="100%" isImageLoading={isImageLoading} />
              <img
                src={info.image}
                alt="project screenshot"
                className={`cursor-pointer hover:opacity-70 ${
                  isImageLoading ? 'hidden' : ''
                }`}
                onClick={() => handleImgClick(info.id)}
                style={{ width: '90%' }}
                onLoad={() => setIsImageLoading(false)}
              />
            </div>
          )}
        </div>

        <div className="detail col-span-2 text-start">
          <div className="font-semibold md:text-xl text-lg">{info.name}</div>
          <div className="mb-4">{info.desc}</div>
          <div className="mb-3">
            {info.tech?.map((tech, index) => (
              <span
                key={index}
                className="inline-block bg-gray-200 rounded-none px-2 text-sm font-semibold text-gray-700 mr-2 mb-1"
              >
                #{tech}
              </span>
            ))}
          </div>

          <div id="project-card-icons" className="flex justify-center pt-4 md:justify-start md:pt-0 items-center h-7 mb-4">
            <a
              className="mr-1 cursor-pointer tooltip"
              data-tip="check app"
              href={info.linkEnabled ? info.appLink : '#'}
              target={info.linkEnabled ? "_blank" : ''}
              rel="noopener noreferrer"
            >
              <BsLink45Deg className="h-6 w-6" />
            </a>
            <a
              className="mx-1 tooltip"
              data-tip="code repo"
              href={info.linkEnabled ? info.gitRepo : '#'}
              target={info.linkEnabled ? "_blank" : ''}
              rel="noopener noreferrer"
            >
              <BsCodeSlash className="h-5 w-5" />
            </a>
            <button
              className="mx-1 tooltip"
              data-tip="like"
              onClick={handleLike}
            >
              {isLike ? (
                <BsHandThumbsUpFill className="h-5 w-5" />
              ) : (
                <BsHandThumbsUp className="h-5 w-5" />
              )}
            </button>
            <button
              className="mx-1 tooltip"
              data-tip="dislike"
              onClick={handleDislike}
            >
              {isDislike ? (
                <BsHandThumbsDownFill className="h-5 w-5" />
              ) : (
                <BsHandThumbsDown className="h-5 w-5" />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
