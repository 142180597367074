import ProjectCard from '../components/project/ProjectCard';
import BackToTop from '../components/utils/BackToTop';

const Project = () => {
  const AWS_S3_URL =
    'https://zkzhang-projects-media.s3.us-west-2.amazonaws.com';
  const projects = [
    {
      id: 1,
      image: `${AWS_S3_URL}/bsds.jpg`,
      name: 'Giant Tigle – Scalable Distributed System',
      desc: 'Handled heavy concurrent POST requests (1.3 million over 280 seconds), persisted 7 million purchase records on cloud relational database; Featuring Top-K query',
      tech: [
        'Scalable',
        'Distributed',
        'Java',
        'MySQL',
        'RabbitMQ',
        'LoadBalancer',
        'Microservice',
        'AWS',
        'EC2',
        'RDS',
        'Concurrency',
      ],
      gitRepo:
        'https://github.com/kevz17/building-scalable-distributed-systems-a3',
      linkEnabled: true,
    },

    {
      id: 2,
      image: `${AWS_S3_URL}/satnoon.jpg`,
      name: 'SatNoon Flea Market – Full Stack Online Selling Web App',
      desc: 'Developed under agile/scrum methodology, demonstrating leadership and troubleshooting',
      tech: [
        'Java',
        'Spark',
        'Dagger',
        'JavaScript',
        'React',
        'Agile',
        'Swagger',
        'OpenAPI',
        'Scrum',
        'Sprint',
        'SDLC',
        'HTML',
        'CSS',
      ],
      appLink: '#',
      gitRepo: '#',
      linkEnabled: false,
    },

    {
      id: 3,
      image: `${AWS_S3_URL}/personal_web.jpg`,
      name: 'Portfolio Website - MERN Stack Interactive Web App',
      desc: 'Incorporated backend admin management console, Twilio SendGrid message/email forwarding, and Google analytics',
      tech: [
        'React',
        'JavaScript',
        'Twilio',
        'SendGrid',
        'Node.js',
        'Express.js',
        'MongoDB',
        'Passport',
        'Session',
        'TailwindCSS',
      ],
      appLink: 'https://zkzhang.com',
      gitRepo: 'https://github.com/kevz17',
    },

    {
      id: 4,
      image: `${AWS_S3_URL}/cat_park.jpg`,
      name: 'Cat Park - MERN Stack Progressive Web App (PWA)',
      desc: 'Featuring push notidication, offline access, user authentication & authorization, and 3rd party APIs',
      tech: [
        'JavaScript',
        'Node.js',
        'Express.js',
        'MongoDB',
        'JWT',
        'React',
        'HTML',
        'CSS',
      ],
      gitRepo: 'https://github.com/kevz17/cat-park-mern',
      linkEnabled: true,
    },

    {
      id: 5,
      image: `${AWS_S3_URL}/crowdfunding_insights.jpg`,
      name: 'Crowdfunding Insights - Data-driven RDBMS Web App',
      desc: 'A strong-relational-model focused app, involving data model design, raw data trimming, data access layer building, and server-side rendering',
      tech: [
        'Java',
        'UML',
        'SQL',
        'JSP',
        'JDBC',
        'DataTrimming',
        'CSS',
        'Kaggle',
      ],
      appLink: 'https://zkzhang.com',
      gitRepo: 'https://github.com/kevz17',
      linkEnabled: false,
    },

    {
      id: 6,
      image: `${AWS_S3_URL}/payment_page.jpg`,
      name: 'Payment Page - Formatted Card Input Frontend',
      desc: "A front-end UI component featuring card issuer's auto detection and number inputs formatting",
      tech: [
        'JavaScript',
        'Node.js',
        'React',
        'Material UI',
        'NPM Library',
        'AWS Amplify',
      ],
      appLink: 'https://main.d35juymfryvrxi.amplifyapp.com',
      gitRepo: 'https://github.com/kevz17/payment-page',
      linkEnabled: false,
    },

    {
      id: 7,
      image: `${AWS_S3_URL}/insta.jpg`,
      name: 'Insta Z - Python Django Backend (SSR)',
      desc: 'An Instagram mockup website developed under MVC pattern and REST architecture with CRUD operations',
      tech: ['Python', 'Django', 'SQLite'],
      appLink: '#',
      gitRepo:
        '#',
        linkEnabled: false,
    },

    {
      id: 8,
      image: `${AWS_S3_URL}/url_shortener.jpg`,
      name: 'URL Shortener - Small Full Stack Web App',
      desc: 'Unbranded and branded URL shortening with URL deletion and editting functionalities',
      tech: [
        'JavaScript',
        'Node.js',
        'Express.js',
        'MongoDB',
        'React',
        'HTML',
        'CSS',
      ],
      appLink: '#',
      gitRepo: 'https://github.com/kevz17',
      linkEnabled: false,
    },

    {
      id: 9,
      image: `${AWS_S3_URL}/pacman_demo.mp4`,
      name: 'Pac-Man Mini',
      desc: 'A simplified classic arcade game - Pac-Man',
      tech: ['Python', 'ProcessingIDE', 'ClassicGame'],
      gitRepo:
        '#',
      isVideo: true,
      linkEnabled: false,
    },

    {
      id: 10,
      image: `${AWS_S3_URL}/othello_demo.mp4`,
      name: 'Othello Board Game',
      desc: "Two-player strategy board game with legal move indication and computer player's improved algorithm",
      tech: ['Python', 'ProcessingIDE', 'ComputerPlayer'],
      gitRepo:
        '#',
      isVideo: true,
      linkEnabled: false,
    },
  ];

  return (
    <div id="scroll-top" className="md:mx-16">
      {projects.map((project, index) => (
        <ProjectCard
          key={index}
          info={project}
          imgProjects={projects.slice(0, projects.length - 2)}
        />
      ))}
      <BackToTop />
    </div>
  );
};

export default Project;
