import axios from 'axios';

const auth = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/v1/admin`,
  withCredentials: true,
});

export const getAuth = async () => {
  const res = await auth.get('/auth');
  return {
    auth: res.data.success,
    user: res.data.data?.username,
  };
};

export const login = async (username, password) => {
  const res = await auth.post('/login', {
    username: username.trim(),
    password,
  });
  return {
    auth: res.data.success,
    user: res.data.data.username,
  };
};

export const logout = async () => {
  await auth.get('/logout');
};

export const register = async (username, password, code) => {
  const res = await auth.post('/', {
    username: username.trim(),
    password,
    idCode: code,
  });
  return {
    auth: res.data.success,
    user: res.data.data.username,
  };
};

export const changePassword = async (user, currentPassword, newPassword) => {
  await axios.put(`/${user}`, {
    currentPassword,
    newPassword,
  });
};
