import { useEffect } from 'react';
import { AuthProvider } from './context/auth/AuthContext';
import { UtilsProvider } from './context/utils/UtilsContext';
import { AlertProvider } from './context/alert/AlertContext';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGa from 'react-ga';
import Page from './components/layout/Page';

const App = () => {
  useEffect(() => {
    ReactGa.initialize('UA-197572295-1');
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <UtilsProvider>
      <AuthProvider>
        <AlertProvider>
          <Router>
            <Page />
          </Router>
        </AlertProvider>
      </AuthProvider>
    </UtilsProvider>
  );
};

export default App;
