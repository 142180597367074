import ContentLoader from 'react-content-loader';

const ImageLoader = ({ width, isImageLoading }) => {
  return (
    <ContentLoader
      speed={2}
      width={256}
      height={128}
      viewBox="0 0 256 128"
      backgroundColor="#f3f3f3"
      foregroundColor="#e0e0e0"
      style={{ width: width }}
      className={`${!isImageLoading ? 'hidden' : ''}`}
    >
      <rect x="0" y="0" rx="2" ry="2" width="256" height="128" />
    </ContentLoader>
  );
};

export default ImageLoader;
