import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { IoStatsChart } from 'react-icons/io5';
import { BsFillSunFill, BsFillMoonFill } from 'react-icons/bs';
import AlertContext from '../../context/alert/AlertContext';
import UtilsContext from '../../context/utils/UtilsContext';
import { switchTheme, fetchGaData } from '../../context/utils/UtilsActions';
import GaReport from '../layout/GaReport';

const ToolBar = () => {
  const GITHUB_URL = 'https://github.com/kevz17';
  const LINKEDIN_URL = 'https://www.linkedin.com/in/zhiwei-zhang-kevin';
  const { lightTheme, theme, dispatch, path } = useContext(UtilsContext);
  const { setAlert } = useContext(AlertContext);
  const darkStyle = {
    boxShadow:
      '0 0.125rem 0.5rem rgba(255, 255, 255, 0.2), 0 0.0625rem 0.125rem rgba(255, 255, 255, 0.2)',
  };
  const lightStyle = {
    boxShadow: theme.light.selfintro.boxShadow,
  };

  const handleThemeChange = () => {
    switchTheme(lightTheme);
    dispatch({ type: 'SWITCH_THEME', payload: lightTheme });
  };

  const handleGoogleAnalytics = async () => {
    try {
      const pageViews = await fetchGaData();
      dispatch({ type: 'SET_PAGE_VIEW', payload: pageViews });
    } catch (err) {
      setAlert('Failed fetching GA data', false, 5000);
    }
  };

  return (
    <div id="toolbar">
      <a
        aria-label="github"
        href={GITHUB_URL}
        target="_blank"
        rel="noopener noreferrer"
        className="tooltip tooltip-right"
        data-tip="GitHub"
      >
        <BsGithub />
      </a>
      <a
        aria-label="linkedin"
        href={LINKEDIN_URL}
        target="_blank"
        rel="noopener noreferrer"
        className="tooltip tooltip-right"
        data-tip="LinkedIn"
      >
        <BsLinkedin />
      </a>
      <div
        className="dropdown dropdown-right dropdown-end tooltip tooltip-right"
        data-tip="show page view"
      >
        <label
          tabIndex="0"
          className="hover:text-indigo-500 hover:cursor-pointer"
          onClick={handleGoogleAnalytics}
        >
          <IoStatsChart />
        </label>
        <div
          tabIndex="0"
          className={`dropdown-content menu p-2 shadow ml-5 w-56 ${
            lightTheme ? 'bg-gray-50 bg-opacity-95' : 'bg-black bg-opacity-90'
          }`}
          style={lightTheme ? lightStyle : darkStyle}
        >
          <span className={lightTheme ? '' : 'dark'}>
            <GaReport />
          </span>
        </div>
      </div>
      <span
        onClick={handleThemeChange}
        className="tooltip tooltip-right"
        data-tip="switch theme"
      >
        {lightTheme ? <BsFillSunFill /> : <BsFillMoonFill />}
      </span>
      <Link
        to={path.home}
        className="tooltip tooltip-right relative -top-5"
        data-tip="home page"
      >
        <span id="home-badge">
          KZ
        </span>
      </Link>
    </div>
  );
};

export default ToolBar;
