import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import UtilsContext from '../../context/utils/UtilsContext';

const NavActionBar = () => {
  const { path, lightTheme, theme } = useContext(UtilsContext);
  const darkStyle = {
    boxShadow:
      '0 0.125rem 0.5rem rgba(255, 255, 255, 0.2), 0 0.0625rem 0.125rem rgba(255, 255, 255, 0.2)',
  };
  const lightStyle = {
    boxShadow: theme.light.selfintro.boxShadow,
  };

  return (
    <div
      id="nav-action-bar"
      className="dropdown dropdown-hover dropdown-top dropdown-end"
    >
      <label tabIndex="0">
        <MdMenu />
      </label>
      <ul
        tabIndex="0"
        className={`dropdown-content menu p-2 shadow w-52 ${
          lightTheme ? 'bg-gray-50 bg-opacity-95' : 'bg-black bg-opacity-90'
        }`}
        style={lightTheme ? lightStyle : darkStyle}
      >
        <li className={lightTheme ? '' : 'dark'}>
          <Link to={path.home}>Home</Link>
        </li>
        <li className={lightTheme ? '' : 'dark'}>
          <Link to={path.about}>About</Link>
        </li>
        <li className={lightTheme ? '' : 'dark'}>
          <Link to={path.project}>Project</Link>
        </li>
        <li className={lightTheme ? '' : 'dark'}>
          <Link to={path.contact}>Contact</Link>
        </li>
        <li className={lightTheme ? '' : 'dark'}>
          <Link to={path.version}>Version</Link>
        </li>
      </ul>
    </div>
  );
};

export default NavActionBar;
