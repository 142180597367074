import { Outlet } from 'react-router-dom';
import DashControl from '../components/dashboard/DashControl';

const Dashboard = () => {
  return (
    <div id="dashboard">
      <DashControl />
      <div className="flex flex-col grow justify-center items-center max-w-6xl mx-auto overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
