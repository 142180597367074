import { useContext } from 'react';
import UtilsContext from '../../context/utils/UtilsContext';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const { path } = useContext(UtilsContext);

  const activeLink = 'active-navlink';
  const inactiveLink = 'inactive-navlink';

  return (
    <nav className="nav-list">
      <NavLink to={path.home} className="nav-item">
        {({ isActive }) => (
          <span className={isActive ? activeLink : inactiveLink}>home</span>
        )}
      </NavLink>
      <NavLink to={path.about} className="nav-item">
        {({ isActive }) => (
          <span className={isActive ? activeLink : inactiveLink}>about</span>
        )}
      </NavLink>
      <NavLink to={path.project} className="nav-item">
        {({ isActive }) => (
          <span className={isActive ? activeLink : inactiveLink}>projects</span>
        )}
      </NavLink>
      <NavLink to={path.contact} className="nav-item">
        {({ isActive }) => (
          <span className={isActive ? activeLink : inactiveLink}>contact</span>
        )}
      </NavLink>
      <NavLink to={path.version} className="nav-item">
        {({ isActive }) => (
          <span className={isActive ? activeLink : inactiveLink}>version</span>
        )}
      </NavLink>
    </nav>
  );
};

export default Navbar;
