import React from 'react';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import Snake from 'react-simple-snake';

const Page404 = () => {
  return (
    <div id="page-404">
      <div className="flex flex-col justify-center items-center">
        <div className="flex justify-center items-center text-xl">
          <BsExclamationTriangleFill className="text-red-600" />
          <span className="mx-2">[Error 404] Page Not Found</span>
        </div>
        <div id="snake-game" className="my-6" style={{ width: '300px' }}>
          <Snake
            percentageWidth={100}
            startSnakeSize={4}
            snakeColor="rgba(67, 56, 202, 1)"
            appleColor="red"
          />
        </div>
      </div>
    </div>
  );
};

export default Page404;
