import { useContext } from 'react';
import { Link } from 'react-router-dom';
import UtilsContext from '../../context/utils/UtilsContext';

const Footer = () => {
  const { currVersion, path, lightTheme } = useContext(UtilsContext);
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className="absolute left-1/2 bottom-0 md:py-4 z-10 rounded-xl"
      style={{
        transform: 'translate(-50%, 0)',
        backgroundColor: lightTheme ? 'inherit' : '#18131f',
      }}
    >
      <div className="mr-1 inline-block">
        <span className="copyright">Copyright</span>
        <span> &copy; {currentYear} zkzhang.com</span>
      </div>
      <Link
        className="version cursor-pointer hover:text-indigo-500 ml-1 inline-block"
        data-tip="version log"
        to={path.version}
      >
        v{currVersion}
      </Link>
    </footer>
  );
};

export default Footer;
