import { createContext, useReducer } from 'react';
import utilsReducer from './UtilsReducer';

const UtilsContext = createContext();

export const UtilsProvider = ({ children }) => {
  const initialState = {
    currVersion: '4.0.2',
    pageView: {
      pageviewTotal: '',
      pageview7Days: '',
    },
    path: {
      home: '/',
      about: '/about',
      project: '/project',
      contact: '/contact',
      version: '/version',
      admin: '/admin',
      dashboard: '/dashboard',
      profile: 'profile',
      message: 'message',
      dashMessage: '/dashboard/message',
      dashProfile: '/dashboard/profile',
      unauth: '/unauthorized',
      resume: '/resume',
    },
    color: {
      bgDark: '#14213d',
      bgLight: 'rgba(249, 250, 251, 1)',
      textDark: 'rgba(229, 231, 235, 1)',
      btnHighlight: 'rgba(156, 163, 175, 0.7)',
    },
    lightTheme:
      localStorage.getItem('light-theme') === undefined
        ? true
        : localStorage.getItem('light-theme'),
    theme: {
      dark: {
        background: 'linear-gradient(to top left, #18131f, #333333)',
        color: '#fff',
        selfintro: {
          boxShadow:
            '0 0.125rem 0.5rem rgba(255, 255, 255, 0.5), 0 0.0625rem 0.125rem rgba(255, 255, 255, 0.5)',
        },
      },
      light: {
        background: 'rgb(249 250 251/var(--tw-bg-opacity))',
        color: '#333',
        selfintro: {
          boxShadow:
            '0 0.125rem 0.5rem rgba(0, 0, 0, 0.3), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2)',
        },
      },
    },
  };

  const [state, dispatch] = useReducer(utilsReducer, initialState);

  return (
    <UtilsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </UtilsContext.Provider>
  );
};

export default UtilsContext;
