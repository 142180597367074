import { useContext, useEffect } from 'react';
import UtilsContext from '../../context/utils/UtilsContext';
import { loadTheme } from '../../context/utils/UtilsActions';
import Footer from './Footer';
import Navbar from './Navbar';
import PageRoutes from './PageRoutes';
import ToolBar from '../utils/ToolBar';
import NavActionBar from '../utils/NavActionBar';
import AlertMessage from '../utils/AlertMessage';
import AdminBadge from '../utils/AdminBadge';

const Page = () => {
  const { lightTheme, theme, dispatch } = useContext(UtilsContext);
  const darkStyle = {
    background: theme.dark.background,
    color: theme.dark.color,
  };
  const lightStyle = {
    background: theme.light.background,
    color: theme.light.color,
  };

  useEffect(() => {
    const isLightTheme = loadTheme();
    dispatch({ type: 'SET_THEME', payload: isLightTheme });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="app-root" style={lightTheme ? lightStyle : darkStyle}>
      <header className="absolute left-8 top-12">
        <Navbar />
      </header>
      <main className="grow grid grid-rows-3">
        <PageRoutes />
      </main>
      <Footer />
      <ToolBar />
      <NavActionBar />
      <AlertMessage />
      <AdminBadge />
    </div>
  );
};

export default Page;
