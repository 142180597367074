import { useEffect, useState, useContext } from 'react';
import { BsX } from 'react-icons/bs';
import axios from 'axios';
import UtilsContext from '../../context/utils/UtilsContext';
import AlertContext from '../../context/alert/AlertContext';

const MessageDetail = ({ detailContact, handleClose, getAllContactInfo }) => {
  const { lightTheme } = useContext(UtilsContext);
  const { setAlert } = useContext(AlertContext);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const detailBannerStyle = lightTheme ? 'bg-gray-200' : 'bg-gray-600';

  const handleUnview = async () => {
    try {
      await axios({
        method: 'put',
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/contacts/${detailContact._id}/view`,
        data: { isViewed: false },
      });
      handleClose();
      getAllContactInfo();
    } catch (err) {
      setAlert(err.response.data.error, false, 5000);
    }
  };

  const handleConfirmDelete = () => {
    setConfirmDelete(true);
  };

  const handleDelete = async () => {
    try {
      await axios({
        method: 'delete',
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/v1/contacts/${detailContact._id}`,
      });
      handleClose();
      getAllContactInfo();
    } catch (err) {
      setAlert(err.response.data.error, false, 5000);
    }
  };

  useEffect(() => {
    getAllContactInfo();

    const close = e => {
      if (e.keyCode === 27) {
        handleClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`fixed mt-10 left-0 right-0 w-8/12 max-w-3xl ml-auto mr-auto flex flex-col justify-start items-center ${
        lightTheme ? 'bg-gray-50' : 'bg-zinc-800'
      }`}
    >
      <div className="shadow-md w-full">
        <div
          className={`flex justify-between items-center h-6 w-full ${detailBannerStyle}`}
        >
          <span
            className="bg-red-400 rounded-full cursor-pointer ml-3 mr-1 hover:text-black"
            title="close"
            onClick={handleClose}
          >
            <BsX />
          </span>
          <span
            className="ml-auto mr-2 cursor-pointer text-xs hover:underline"
            onClick={handleUnview}
          >
            mark as unviewed
          </span>
        </div>
        <div className="message-detail-content">
          <div>
            <span>Name</span>
            <span>{detailContact.name}</span>
          </div>
          <div>
            <span>Email</span>
            <span>{detailContact.email}</span>
          </div>
          <div>
            <span>Subject</span>
            <span>{detailContact.subject}</span>
          </div>
          <div>
            <span>Message</span>
            <span>{detailContact.message}</span>
          </div>
          <div>
            <span>Resume</span>
            <span>
              {detailContact.requiresResume ? 'requested' : 'not requested'}
            </span>
          </div>
          <div>
            <button>Edit</button>
            {confirmDelete ? (
              <button onClick={handleDelete}>Confirm Delete</button>
            ) : (
              <button onClick={handleConfirmDelete}>Delete</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageDetail;
