import { BsExclamationTriangleFill } from 'react-icons/bs';

const Page401 = () => {
  return (
    <div id="page-401" className="text-center">
      <div className="flex justify-center items-center text-xl">
        <BsExclamationTriangleFill className="text-red-600" />
        <span className="mx-2">[Error 401] Access Denied</span>
      </div>
    </div>
  );
};

export default Page401;
