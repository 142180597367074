import axios from 'axios';

export const switchTheme = lightTheme => {
  localStorage.setItem('light-theme', !lightTheme);
};

export const loadTheme = () => {
  return localStorage.getItem('light-theme') === 'true';
};

export const fetchGaData = async () => {
  const res = await axios({
    method: 'get',
    withCredentials: true,
    url: `${process.env.REACT_APP_BACKEND_URL}/v1/ga`,
  });

  if (res.data) {
    return {
      pageviewTotal: res.data.pageviewTotal,
      pageview7Days: res.data.pageview7Days,
    };
  } else {
    return 'N/A';
  }
};
