import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { BsEnvelope } from 'react-icons/bs';
import UtilsContext from '../context/utils/UtilsContext';

const About = () => {
  const { path, lightTheme, theme } = useContext(UtilsContext);
  const darkStyle = {
    boxShadow: theme.dark.selfintro.boxShadow,
  };
  const lightStyle = {
    boxShadow: theme.light.selfintro.boxShadow,
  };

  return (
    <div id="about">
      <article style={lightTheme ? lightStyle : darkStyle}>
        <p>
          <FaQuoteLeft className="pb-2 inline-block" />I was a mechanical
          engineer, and my previous work involved using the software as a tool
          to test mechanical components. After transitioning to the I.T.
          industry as a software engineer, I finally found what I genuinely like
          to do.
          <FaQuoteRight className="pb-2 inline-block" />
        </p>
        <div className="flex justify-end">
          <Link to={path.contact} className="cursor-pointer hover:opacity-50">
            <BsEnvelope className="text-2xl" />
          </Link>
        </div>
      </article>
    </div>
  );
};

export default About;
