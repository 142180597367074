import { useContext } from 'react';
import LogBlock from '../components/version/LogBlock';
import UtilsContext from '../context/utils/UtilsContext';

const Version = () => {
  const { currVersion } = useContext(UtilsContext);

  const versions = [
    {
      ver: currVersion,
      features: [
        'Migrated from Heroku dyno host to Amazon EC2 instances with application load balancer and auto scaling group configured via AWS Elastic Beanstalk.',
        'Minor UI updates.',
      ],
      issues: [
        'Webpage content on the top and bottom of the screen will be covered when browsing on small screen device using Google Chrome for iOS (Version 100.0.4896.56) or Safari for iOS (Version 15.4).',
      ],
    },
    {
      ver: '4.0.1',
      features: [
        'Moved local media content to Amazon S3 for better loading performance.',
        'Added image loader for better user experience.',
      ],
    },
    {
      ver: '4.0.0',
      features: [
        'Newly created name servers to host backend server.',
        'Adjustment to backend API endpoints.',
        'Fixed the access issue from mobile devices.',
      ],
    },
    {
      ver: '3.1.0',
      features: [
        'Almost re-designed 2nd generation user interface (UI) using daisyUI.',
        'Optimized responsive design for smartphone users.',
        'Replaced the advertisement poster with a message modal.',
      ],
      issues: ['Cannot access admin console from mobile devices.'],
    },
    {
      ver: '3.0.0',
      features: [
        'Incorporated Twilio SendGrid API to forward messages sent from users.',
        'Added Google reCAPTCHA v2 to reduce non-human behaviors.',
        'Added resume request channel.',
        'Adjustment to admin console UI.',
      ],
    },
    {
      ver: '2.0.0',
      features: [
        'Incorporated Google Analytics API for page views display.',
        'Admin console improvements: easy access, detail view, contacts management (view status indicator, CRUD operations).',
        'Advertisement poster on landing page.',
      ],
      bugs: ['Admin console contact info table overflow.'],
    },
    {
      ver: '1.2.0',
      features: [
        'Admin console to manage backend data.',
        `User authentication and authorization (content protection) via Passport local strategy and cookie session.`,
        'Unauthorized page for denied access.',
      ],
      bugs: ['Contact form sending error.'],
    },
    {
      ver: '1.1.0',
      features: [
        'Pulse animation for the advertisement block.',
        'Interaction with menu bars of project cards: close, minimize, and maximize (default).',
        'Contact form status message to users.',
        'Classic snake game on 404 page for desktop users.',
      ],
      bugs: [
        'Empty input or whitespaces would be prevented from sending on contact page.',
        'Updates Log page content overflow.',
      ],
    },
    {
      ver: '1.0.0',
      features: [
        'Theme toggling between light and dark.',
        'Thumbs-up/down buttons.',
        'Contact form to get in touch with users.',
        'Updates/Changes log listing newly added features and fixed bugs.',
      ],
      bugs: [
        "Persisted the theme selection. Now user's choice of theme will be preserved.",
        'Footer background changed to match the theme color.',
      ],
    },
    {
      ver: '0.3.0',
      features: [
        'Projects displayed as cards.',
        'A brief self-introduction on MORE page.',
        'Tailwind CSS framework.',
      ],
    },
    {
      ver: '0.2.0',
      features: [
        'React as front-end development tool with Bootstrap as CSS framework.',
        'Website made to be responsive to different screen size.',
      ],
    },
    {
      ver: '0.1.0',
      features: [
        'Static content display including projects showcase, public contact information, and external links to GitHub and LinkedIn.',
        'Plain HTML and CSS used.',
      ],
    },
  ];

  return (
    <div id="version">
      <h2 className="md:mx-8 mt-4 mb-2">Tech Stack</h2>
      <div className="md:grid md:grid-cols-2 md:grid-rows-3 md:justify-items-start mb-4 flex flex-col justify-center items-start mx-8 border-b-2 border-dashed border-gray-500 pb-4">
        <p>DNS Nameserver: Amazon Route 53</p>
        <p>Frontend Host: Amazon S3/CloudFront (via AWS Amplify)</p>
        <p>Backend Host: Amazon EC2 (via AWS Elastic Beanstalk)</p>
        <p>Database: MongoDB Atlas</p>
        <p>Object Storage: Amazon S3</p>
      </div>
      {versions.map((ver, index) => (
        <LogBlock
          key={index}
          content={ver}
          isCurrent={index === 0}
          isLast={index === versions.length - 1}
        />
      ))}
    </div>
  );
};

export default Version;
