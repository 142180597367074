import { useState, useContext, useEffect } from 'react';
import { BsEnvelope } from 'react-icons/bs';
import TwilioSendGridIcon from '../components/icon/TwilioSendGridIcon';
import axios from 'axios';
import UtilsContext from '../context/utils/UtilsContext';
import ReCaptchaV2 from 'react-google-recaptcha';
import AlertContext from '../context/alert/AlertContext';

const Contact = () => {
  const { lightTheme } = useContext(UtilsContext);
  const { setAlert } = useContext(AlertContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isRecaptchaPassed, setIsRecaptchaPassed] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [requiresResume, setRequiresResume] = useState(false);
  const maxNameLen = 50;
  const maxEmailLen = 320;
  const maxMsgLen = 1000;
  const contactStyle =
    'flex flex-col m-auto w-full py-6 px-10 max-w-xl justify-start items-center border-dashed border-2 border-gray-500';
  const params = new URLSearchParams(window.location.search);

  const isValidInput = () => {
    let content = '';
    let isValid = false;

    if (name.trim() === '' || message.trim() === '') {
      content = 'Name/Message cannot be empty or whitespaces';
    } else if (name.trim().length > maxNameLen) {
      content = `Name must be less than ${maxNameLen} characters`;
    } else if (
      email.trim().length !== 0 &&
      !email.trim().match(/^\w+@(\w+\.)+\w+$/)
    ) {
      content = 'Invalid email address';
    } else if (email.trim().length >= maxEmailLen) {
      content = `Email address must be within ${maxEmailLen} characters`;
    } else if (message.length > maxMsgLen) {
      content = `Message is limited to ${maxMsgLen} characters`;
    } else if (!isRecaptchaPassed) {
      content = 'Failed reCAPTCHA verification';
    } else {
      isValid = true;
    }
    return [isValid, content];
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    setIsBtnDisabled(true);
    const [valid, content] = isValidInput();

    if (!valid) {
      setAlert(content, valid, 5000);
      setIsBtnDisabled(false);
    } else {
      document.getElementById('form').reset();

      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/contacts`, {
          name,
          email,
          subject,
          message,
          requiresResume,
        });
        setIsBtnDisabled(false);
        setAlert('Your message has been sent, thank you!', true, 5000);

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/email`, {
          name,
          email,
          subject,
          message,
          requiresResume,
        });
      } catch (err) {
        setIsBtnDisabled(false);
        setAlert('Failed sending message, please try later', false, 5000);
      }
    }
  };

  const verifyRecaptchaToken = async token => {
    try {
      const {
        data: { recaptcha: res },
      } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/recaptcha`,
        {
          token,
        }
      );
      setIsRecaptchaPassed(res.success);
    } catch (err) {}
  };

  const onRecaptchaChange = token => {
    verifyRecaptchaToken(token);
  };

  const handleRecaptchaExpired = () => {
    setAlert('Verification expired. Check the checkbox again', false, 6000);
  };

  useEffect(() => {
    setRequiresResume(params.get('resume') === 'true');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="contact" className="flex">
      <div className={contactStyle}>
        <div className="form-title">
          <BsEnvelope />
          <span className="ml-2 cursor-default">Get in Touch</span>
        </div>
        <form id="form" onSubmit={handleFormSubmit}>
          <div className="form-div">
            <label className="contact-label" htmlFor="name">
              <span>Name</span>&nbsp;
              <span className="text-red-600 mr-2">*</span>
            </label>
            <input
              className="contact-input"
              type="text"
              name="name"
              id="name"
              autoComplete="off"
              onChange={e => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-div">
            <label
              className="contact-label tooltip"
              htmlFor="email"
              data-tip="if you'd like return communication"
            >
              Email
            </label>
            <input
              className="contact-input"
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="form-div">
            <label className="contact-label" htmlFor="subject">
              Subject
            </label>
            <input
              className="contact-input"
              type="text"
              name="subject"
              id="subject"
              autoComplete="off"
              onChange={e => setSubject(e.target.value.trim())}
            />
          </div>
          <div className="flex justify-between">
            <label className="mb-3" htmlFor="message">
              <span>Message</span>&nbsp;
              <span className="text-red-600">*</span>
            </label>
          </div>
          <textarea
            className="contact-textarea h-32 resize-none font-light"
            maxLength={maxMsgLen}
            name="message"
            id="message"
            autoComplete="off"
            required
            onChange={e => setMessage(e.target.value)}
          ></textarea>
          <div className="flex justify-between font-medium text-xs">
            <span className="cursor-default">
              <strong className="text-red-600">*</strong> Required
            </span>
            <span className="cursor-default">
              {message.length}/{maxMsgLen} Characters
            </span>
          </div>
          {process.env.REACT_APP_RECAPTCHA_SITE_KEY ? (
            <ReCaptchaV2
              className="mr-auto mt-2 g-recaptcha text-opacity-0"
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onRecaptchaChange}
              theme={lightTheme ? 'light' : 'dark'}
              onExpired={handleRecaptchaExpired}
            />
          ) : (
            <div className="text-center text-indigo-600 font-bold">
              ReCaptcha failed loading
            </div>
          )}
          <div className="flex justify-center text-xs cursor-default">
            <span>Powered by </span>
            <a
              className="flex justify-center items-center"
              href="https://www.twilio.com/sendgrid/email-api"
              target="_blank"
              rel="noreferrer"
            >
              <span className="mx-1 font-bold">Twilio</span>
              <TwilioSendGridIcon lightTheme={lightTheme} />
            </a>
          </div>
          <button
            type="submit"
            className="btn-customized"
            disabled={isBtnDisabled}
            style={{ backgroundColor: isBtnDisabled && 'gray' }}
          >
            {isBtnDisabled ? 'PROCESSING ...' : 'SEND'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
