import React from 'react';
import { FaAngleDoubleUp } from 'react-icons/fa';

const BackToTop = () => {
  const handleScrollToTop = () => {
    const projectsWindow = document.getElementById('scroll-top');
    projectsWindow.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className="flex max-w-fit flex-col mx-auto items-center cursor-pointer hover:opacity-50 mb-8"
      onClick={handleScrollToTop}
    >
      <FaAngleDoubleUp className="w-5 h-5" />
      <span className="text-sm">back to top</span>
    </div>
  );
};

export default BackToTop;
